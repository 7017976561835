<mat-form-field
        appearance="outline"
        [formGroup]="formGroup"
        style="margin-bottom: -1.25em; width: 100%;"
>
    <mat-label>{{config.label}} {{requerido? '*' : '' }}</mat-label>

    <input *ngIf="config.inputTipo !== 'textarea'; else textarea"
           #input
           matInput
           [placeholder]="config.placeholder || ''"
           [formControl]="controle"
           [type]="config.inputTipo === 'password' ? esconderSenha ? 'password' : 'text' : config.inputTipo || ''"
           maxlength="{{config.tamanhoMaximo ? config.tamanhoMaximo : undefined }}"
           minlength="{{config.tamanhoMinimo ? config.tamanhoMinimo : undefined }}"
           [ngClass]="{
      upercase : config.todasMaiusculas
    }"
           min="{{config.rangeMinimo ? config.rangeMinimo : undefined }}"
           max="{{config.rangeMaximo ? config.rangeMaximo : undefined }}"
           step="{{config.step ? config.step : 1 }}"
           [mask]="config.mascara || ''"
           [dropSpecialCharacters]="config.removerCaracteresMascara || false"
           [readonly]="config.readonly"
    />
    <ng-template #textarea>
  <textarea  #input *ngIf="config.inputTipo == 'textarea'"
             matInput
             [placeholder]="config.placeholder || ''"
             [formControl]="controle"
             [type]="config.inputTipo"
             maxlength="{{config.tamanhoMaximo ? config.tamanhoMaximo  : undefined }}"
             minlength="{{config.tamanhoMinimo ? config.tamanhoMinimo  : undefined }}"
             [ngClass]="{
    upercase : config.todasMaiusculas}"
             [readonly]="config.readonly"
             rows="config.linhas"></textarea>
    </ng-template>
    <mat-icon *ngIf="config.inputTipo === 'password'"  matSuffix (click)="esconderSenha = !esconderSenha">{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
    <mat-icon *ngIf="config.sufixoIcone" matSuffix
    >{{config.sufixoIcone}}</mat-icon
    >
    <mat-icon *ngIf="config.prefixoIcone" matPrefix
    >{{config.prefixoIcone}}</mat-icon
    >
</mat-form-field>
