import { Selector } from '@ngxs/store';
import {
  ConfirmacaoUsuarioStateModel, UsuariosManutencaoState
} from "@identidade/features/usuario-manutencao/usuario-manutencao-store/usuario-manutencao.state";

export class UsuarioManutencaoSelector {

  @Selector([UsuariosManutencaoState])
  public static processando(state: ConfirmacaoUsuarioStateModel): boolean {
    return !!(state && state.processando);
  }

  @Selector([UsuariosManutencaoState])
  public static confirmado(state: ConfirmacaoUsuarioStateModel): boolean {
    return state.confirmado;
  }

    @Selector([UsuariosManutencaoState])
    public static tokenInvalido(state: ConfirmacaoUsuarioStateModel): boolean {
        return state.tokenInvalido;
    }

}
