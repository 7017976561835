import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from "@angular/forms";
import {ConfirmacaoUsuario, ReenviarEmailConfirmacao} from "./usuario-manutencao-store/usuario-manutencao.actions";
import {Store} from "@ngxs/store";
import {RecuperarSenha} from "@identidade/features/login/login-store/login.actions";

@Injectable({ providedIn: 'root' })
export class UsuarioManutencaoService {

    public token: string;
    public email: string;
    public recuperarSenha: boolean;

    constructor(
        private route: ActivatedRoute,
        private store: Store
    ) {

        const { email, token, recuperarsenha } = this.route.snapshot.queryParams;
        this.token = token;
        this.email = email;
        this.recuperarSenha = recuperarsenha == "true";
    }

    public confirmar = ({ value }: FormGroup) => {
        value.token = this.token;
        value.email = this.email;
        value.recuperarSenha = this.recuperarSenha;
        return this.store.dispatch(new ConfirmacaoUsuario(value));
    };

    public reenviarEmail = (recuperarSenha: boolean) => {
        if(recuperarSenha){
            return this.store.dispatch(new RecuperarSenha(this.email));
        }

        return this.store.dispatch(new ReenviarEmailConfirmacao(this.email));
    };
}

