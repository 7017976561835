import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from "@angular/material/icon";
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { UsuarioManutencaoComponent } from "./usuario-manutencao.component";
import { UsuariosManutencaoState } from "./usuario-manutencao-store/usuario-manutencao.state";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [UsuarioManutencaoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxsModule.forFeature([UsuariosManutencaoState]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsuarioManutencaoModule { }
