import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsStoreModule } from './store/store.module';
import { AppComponent } from './app.component';
import { environment } from 'environments/environment';
import {
    API_URL,
    AutoCompleteService,
    ErrorHttpInterceptor,
    JWTInterceptor,
    LoaderInterceptor,
    MASTER_DEFAULT_OPTIONS,
    MasterModule,
    matFormField,
    matSnackbar
} from 'ui-shared';
import { MatDialogModule } from '@angular/material/dialog';
import { master } from './master-config';
import { AcessoNegadoComponent } from './features/acesso-negado/acesso-negado.component';
import { UsuarioManutencaoModule } from "./features/usuario-manutencao/usuario-manutencao.module";
import { registerLocaleData } from "@angular/common";
import ptBr from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { IDENTIDADE_API_URL } from "@identidade/features/const/constantes";
import { OPERACAO_API_URL } from "@catalogo/features/compartilhado/const/constantes";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalService
} from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "@identidade/features/login/login/auth-config";

registerLocaleData(ptBr);

export const LOCAL_FORMATO = {
    parse: {
        dateInput: 'L',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Popup,
        authRequest: loginRequest
    };
}

@NgModule({
    declarations: [
        AppComponent,
        AcessoNegadoComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxsStoreModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatDialogModule,
        MatSnackBarModule,
        MasterModule,
        UsuarioManutencaoModule
    ],
    providers: [
        { provide: MASTER_DEFAULT_OPTIONS, useValue: master },
        { provide: API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: IDENTIDADE_API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: OPERACAO_API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: matSnackbar() },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormField() },
        { provide: AutoCompleteService, useValue: environment.identidade },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MsalGuardConfigurationFactory },
        MsalService,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

