<div class="container-fluid h-100 d-flex flex-column align-items-center justify-content-center mat-app-background">
    <img class="w-50" src="assets/AF_BLUE_ROUTE_HORIZONTAL.png" style="margin-bottom: 50px" alt="Blue Route">
    <h1 style="font-size: xx-large;font-weight: bold;">
        {{recuperarSenha ? 'Recuperação Senha' : 'Confirmação de Usuário'}}
    </h1>
    <mat-card class="w-50 mat-elevation-z10" *ngIf="!(confirmado$ | async) && (tokenInvalido$ | async)">
        <mat-card-title>
            <h1 matCardTitle class="text-center">Token Inválido! Deseja reenviar e-mail de {{recuperarSenha ? 'recuperação' : 'confirmação'}}?</h1>
        </mat-card-title>
        <mat-card-subtitle>
            <h2 matCardSubtitle class="text-center">{{service.email}}</h2>
        </mat-card-subtitle>
        <mat-card-content>
            <form
                class="w-75 h-100 mt-auto mb-1 mx-auto"
                method="POST"
                [formGroup]="usuarioform"
                (ngSubmit)="service.reenviarEmail()"
            >
                <button
                    *ngIf="!(processando$ | async)"
                    color="accent"
                    mat-raised-button
                    type="submit"
                    class="d-block mx-auto"
                >
                    Reenviar
                </button>
            </form>
            <mat-progress-spinner
                *ngIf="(processando$ | async)"
                color="accent"
                mode="indeterminate"
                diameter="40"
                class="d-block mx-auto"
            ></mat-progress-spinner>
        </mat-card-content>
    </mat-card>
    <mat-card class="w-50 mat-elevation-z10" *ngIf="(confirmado$ | async)">
        <mat-card-title>
            <h1 *ngIf="recuperarSenha" matCardTitle class="text-center">
                {{(tokenInvalido$ | async) ? 'E-mail de recuperação enviado!' : 'Senha definida com sucesso!'}}
            </h1>
            <h1 *ngIf="!recuperarSenha" matCardTitle class="text-center">
                {{(tokenInvalido$ | async) ? 'E-mail de confirmação enviado!' : 'Usuário confirmado com sucesso!'}}</h1>
        </mat-card-title>
    </mat-card>
    <mat-card class="w-50 mat-elevation-z10" *ngIf="!(confirmado$ | async) && !(tokenInvalido$ | async)">
        <mat-card-title>
            <h1 matCardTitle class="text-center">Defina a senha para o usuário</h1>
        </mat-card-title>
        <mat-card-subtitle>
            <h2 matCardSubtitle class="text-center">{{service.email}}</h2>
        </mat-card-subtitle>
        <mat-card-content>
            <form
                class="w-75 h-100 mt-auto mb-1 mx-auto"
                method="POST"
                [formGroup]="usuarioform"
                (ngSubmit)="service.confirmar(usuarioform)"
            >
                <mat-form-field class="w-100" >
                    <mat-label style="font-weight: bold;">Senha</mat-label>
                    <input matInput formControlName="senha" [type]="esconderSenha ? 'password' : 'text'"/>
                    <mat-icon matSuffix (click)="esconderSenha = !esconderSenha">{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-100" >
                    <mat-label style="font-weight: bold;">Confirmação Senha</mat-label>
                    <input matInput formControlName="confirmacaoSenha" [type]="esconderConfirmacaoSenha ? 'password' : 'text'"/>
                    <mat-icon matSuffix (click)="esconderConfirmacaoSenha = !esconderConfirmacaoSenha">{{esconderConfirmacaoSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <button
                    *ngIf="!(processando$ | async)"
                    color="accent"
                    [disabled]="!(usuarioform.dirty && usuarioform.valid)"
                    mat-raised-button
                    type="submit"
                    class="d-block mx-auto"
                >
                    Confirmar
                </button>
                <mat-progress-spinner
                    *ngIf="(processando$ | async)"
                    color="accent"
                    mode="indeterminate"
                    diameter="40"
                    class="d-block mx-auto"
                ></mat-progress-spinner>
            </form>
        </mat-card-content>
    </mat-card>
</div>
