import {MasterConfig} from 'ui-shared';

export const master = {
    titulo: 'Identidade',
    rota: '/home',
    imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
    links: [
        { titulo: 'Início', icone: 'home', label: 'Início', rota: '/home' },
        { titulo: 'Contas', icone: 'contacts', label: 'Contas', rota: '/conta' },
        { titulo: 'Contratos', icone: 'import_contacts', label: 'Contratos', rota: '/contrato' },
        { titulo: 'Módulos', icone: 'view_module', label: 'Módulos', rota: '/modulo' },
        { titulo: 'Usuários', icone: 'group', label: 'Usuários', rota: '/usuario' },
    ]
} as MasterConfig;
