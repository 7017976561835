import { API_URL, CrudService } from 'ui-shared';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UsuarioEntity } from '../../models/index';

@Injectable({ providedIn: 'root' })
export class UsuariosDataService extends CrudService<UsuarioEntity> {

    protected url: string;

    constructor(
        @Inject(API_URL) urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/usuario`;
    }

    enviarEmailConfirmacao(usuarioId: string) {
        return this.httpClient.post(this.url + `/ReenviarNaoConfirmado/${usuarioId}`, null);
    }

    confirmarUsuario(email: string, senha: string, token: string) {
        return this.httpClient.get(this.url + `/ConfirmarUsuario?email=${email}&senha=${senha}&token=${token}`);
    }

    desbloquear(usuarioId: string) {
        return this.httpClient.put(this.url + `/${usuarioId}/Desbloquear`, null);
    }
}
