import { NgxsConfig } from '@ngxs/store/src/symbols';
import { NgxsDevtoolsOptions } from '@ngxs/devtools-plugin/src/symbols';
import { NgxsLoggerPluginOptions } from '@ngxs/logger-plugin/src/symbols';
import { environment } from 'environments/environment';
import { EnumAmbiente } from '@env/enum-ambiente';

export const STATES_MODULES = [];

export const OPTIONS_CONFIG: Partial<NgxsConfig> = {
    developmentMode: environment.ambiente === EnumAmbiente.Desenvolvimento,
    selectorOptions: {}
};

export const DEVTOOLS_REDUX_CONFIG: NgxsDevtoolsOptions = {
    disabled: environment.ambiente !== EnumAmbiente.Desenvolvimento
};

export const LOGGER_CONFIG: NgxsLoggerPluginOptions = {
    disabled: environment.ambiente !== EnumAmbiente.Desenvolvimento
};
