import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Subscription } from "rxjs";
import { pairwise, tap } from "rxjs/operators";
import { NotificationService } from "../services";
import { Condicao } from "./models/condicao.model";
import { FiltroOpcao } from "./models/filtro-opcao.model";
import { FiltroSelecionado } from "./models/filtro-selecionado.model";
import { FiltroSubmit } from "./models/filtro-submit.model";
import { Filtro } from "./models/filtro.model";
import {
    BOOLEAN,
    CONTEM,
    DATE,
    DIFERENTE,
    DIFERENTE_SQL,
    ENUM, FILTRO_PLACE_HOLDER,
    GUID,
    IGUAL,
    IGUAL_SQL,
    MAIOR,
    MAIOR_IGUAL,
    MENOR,
    MENOR_IGUAL,
    NAO_CONTEM,
    NAO_PREENCHIDO,
    NUMBER,
    OPTIONS,
    PREENCHIDO,
    TEXT,
    COMPOSICAO_AND,
    COMPOSICAO_OR
} from "./filter-constantes";
import { StringHelper } from "../helpers";

@Component({
    selector: 'ui-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    ],
})
export class FilterComponent implements OnInit, OnDestroy {
    @Output() public submitEvent = new EventEmitter();

    @Input() filtros: Filtro[];
    @Input() selecionados!: FiltroSelecionado[];
    @Input() aberto: boolean = false;
    @Input() formatoSql: boolean = false;

    filtrosForm = new FormArray([]);
    private subscriptions: Subscription[] = [];

    public condicoes: Condicao[] = [
        { descricao: 'Contém', valor: CONTEM, tiposPermitidos: [TEXT, ENUM] } as Condicao,
        { descricao: 'Não contém', valor: NAO_CONTEM, tiposPermitidos: [TEXT, ENUM] } as Condicao,
        { descricao: 'Igual a', valor: IGUAL, tiposPermitidos: [TEXT, NUMBER, BOOLEAN, ENUM, OPTIONS, GUID, DATE] } as Condicao,
        { descricao: 'Diferente de', valor: DIFERENTE, tiposPermitidos: [TEXT, NUMBER, ENUM, OPTIONS, GUID, DATE] } as Condicao,
        { descricao: 'Maior que', valor: MAIOR, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Menor que', valor: MENOR, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Maior ou igual a', valor: MAIOR_IGUAL, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Menor ou igual a', valor: MENOR_IGUAL, tiposPermitidos: [NUMBER, DATE] } as Condicao,
        { descricao: 'Preenchido', valor: PREENCHIDO, tiposPermitidos: [TEXT, NUMBER, BOOLEAN, ENUM, OPTIONS, GUID, DATE] } as Condicao,
        { descricao: 'Não Preenchido', valor: NAO_PREENCHIDO, tiposPermitidos: [TEXT, NUMBER, BOOLEAN, ENUM, OPTIONS, GUID, DATE] } as Condicao,
    ];

    constructor(
        private datePipe: DatePipe,
        private notification: NotificationService,
    ) { }

    ngOnInit(): void {
        const novoForm = this.montaFormArray(this.selecionados);
        this.filtrosForm = new FormArray(novoForm);

        if (this.selecionados) {
            this.filtrosForm.markAsTouched();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public condicoesFiltradas(propriedade: string): Condicao[] {
        let filtro = this.filtros.find(x => x.propriedade == propriedade);

        return this.condicoes.filter(condicao => condicao.tiposPermitidos.includes(filtro?.tipo))
    }

    public removerFiltro(index: number): void {
        if (this.filtrosForm.length === 1) {
            let form = this.filtrosForm.controls[index] as FormGroup;
            form.reset();
        } else {
            this.filtrosForm.removeAt(index);
        }
    }

    public limpaForm(): void {
        this.filtrosForm = new FormArray(this.montaFormArray());
        this.submit();
    }

    public adicionarFiltro(): void {
        const ultimoFiltro = this.filtrosForm.at(this.filtrosForm.length - 1) as FormGroup;

        const camposPreenchidos = Object.keys(ultimoFiltro.controls).every(key => {
            const control = ultimoFiltro.get(key);
            return control && control.value !== null && control.value !== '';
        });

        if (!camposPreenchidos && this.filtrosForm.length > 1) {
            this.notification.openError('Por favor, preencha todos os campos do filtro atual antes de adicionar um novo.');
            return;
        }

        this.filtrosForm.push(this.montaFormGroup());
    }

    public exibirAdicionar(index: number): boolean {
        return this.filtrosForm.length === index + 1;
    }

    public exibirComposicao(index: number): boolean {
        return index > 0;
    }

    public exibirCampoData(filtroSelecionado: AbstractControl): boolean {
        let filtro = this.filtros.find(x => x.propriedade === filtroSelecionado.get('propriedade').value);
        let condicao = filtroSelecionado.get('condicao').value;

        if (condicao == NAO_PREENCHIDO || condicao == PREENCHIDO) {
            return false;
        }

        return filtro?.tipo === DATE;
    }

    public exibirCampoBooleano(filtroSelecionado: AbstractControl): boolean {
        let filtro = this.filtros.find(x => x.propriedade === filtroSelecionado.get('propriedade').value);
        let condicao = filtroSelecionado.get('condicao').value;

        if (condicao == NAO_PREENCHIDO || condicao == PREENCHIDO) {
            return false;
        }

        return filtro?.tipo === BOOLEAN;
    }

    public exibirCampoPadrao(filtroSelecionado: AbstractControl): boolean {
        let filtro = this.filtros.find(x => x.propriedade === filtroSelecionado.get('propriedade').value);
        let condicao = filtroSelecionado.get('condicao').value;

        if (condicao == NAO_PREENCHIDO || condicao == PREENCHIDO) {
            return false;
        }

        return filtro?.tipo !== BOOLEAN && filtro?.tipo !== DATE && filtro?.tipo !== OPTIONS;
    }

    public options(propriedade: string): Array<FiltroOpcao> {
        return this.filtros.find(f => f.propriedade === propriedade)?.opcoes || []
    }

    public exibirOptions(filtroSelecionado: AbstractControl): boolean {
        let filtro = this.filtros.find(x => x.propriedade === filtroSelecionado.get('propriedade').value);
        let condicao = filtroSelecionado.get('condicao').value;

        if (condicao == NAO_PREENCHIDO || condicao == PREENCHIDO) {
            return false;
        }

        return filtro?.tipo === OPTIONS;
    }

    public abrirFiltros(): void {
        this.aberto = !this.aberto;
    }

    public submit() {
        if (this.filtrosForm.controls.length > 1) {
            this.filtrosForm.controls = this.filtrosForm.controls
                .filter((filtro: AbstractControl, index: number) => {
                    const formGroup = filtro as FormGroup;
                    const camposPreenchidos = Object.keys(formGroup.controls).every(key => {
                        const control = formGroup.get(key);
                        return control && control.value !== null && control.value !== '';
                    });

                    if (index === 0) {
                        return true;
                    }

                    return camposPreenchidos;
                });
        }

        const primeiroFiltro = this.filtrosForm.at(0) as FormGroup;
        const primeiroFiltroValido = Object.keys(primeiroFiltro.controls).every(key => {
            const control = primeiroFiltro.get(key);
            return control && (control.value !== null && control.value !== '') || (key == 'composicao');
        });

        if (!primeiroFiltroValido && this.filtrosForm.controls.length > 1) {
            this.notification.openError('Nenhum filtro válido foi encontrado. Por favor, preencha ao menos um filtro para fazer a pesquisa.');
            return;
        }

        let filtrosValidados = this.filtrosForm.controls
            .map(filtro => (filtro as FormGroup).getRawValue() as FiltroSelecionado)
            .filter(filtro => filtro.propriedade && filtro.condicao && filtro.termo);

        if (filtrosValidados.length === 0) {
            filtrosValidados = [];
        }

        this.submitEvent.emit({ query: this.montaFiltro(filtrosValidados), filtros: filtrosValidados } as FiltroSubmit);
    }

    private montaFormArray(filtros: FiltroSelecionado[] = null): FormGroup[] {
        return filtros?.map(filtro => this.montaFormGroup(filtro)) || [this.montaFormGroup()];
    }

    private montaFormGroup(selecionado: FiltroSelecionado = null): FormGroup {
        const form = new FormGroup({
            propriedade: new FormControl(selecionado?.propriedade, [Validators.required]),
            condicao: new FormControl(selecionado?.condicao, [Validators.required]),
            termo: new FormControl(selecionado?.termo),
            composicao: new FormControl(selecionado?.composicao)
        });

        if (selecionado) {
            form.patchValue(selecionado);

            for (let i in form.controls) {
                form.controls[i].markAsTouched();
            }
        }

        this.subscriptions.push(form.valueChanges.pipe(
            pairwise(),
            tap(([anterior, novo]: [any, any]) => {
                const tipoAnterior = this.filtros.find(x => x.propriedade == anterior.propriedade)?.tipo;
                const tipoNovo = this.filtros.find(x => x.propriedade == novo.propriedade)?.tipo;

                if (tipoAnterior == DATE && tipoAnterior != tipoNovo) {
                    let formAtualIndex = this.filtrosForm.getRawValue().findIndex(x =>
                        x.propriedade == novo.propriedade &&
                        x.termo == novo.termo &&
                        x.condicao == novo.condicao
                    );

                    if (formAtualIndex > -1) {
                        let formAtual = this.filtrosForm.controls[formAtualIndex];
                        formAtual.patchValue({ termo: null, condicao: null });
                    }

                }
            })
        ).subscribe());

        return form;
    }

    private montaFiltro(filtros: FiltroSelecionado[]) {
        let query = '';
        let parentesesAberto = false;
        let filtroAberturaParenteses = '';

        for (let index = 0; index < filtros.length; index++) {
            const filtroSelecionado = filtros[index];
            let filtroQuery = ''

            switch (filtroSelecionado.condicao) {
                case CONTEM:
                    filtroQuery = this.montarFiltroContem(filtroSelecionado);
                    break;
                case NAO_CONTEM:
                    filtroQuery = this.montarFiltroNaoContem(filtroSelecionado);
                    break;
                case IGUAL:
                    filtroQuery = this.montarFiltroIgual(filtroSelecionado);
                    break;
                case DIFERENTE:
                    filtroQuery = this.montarFiltroDiferente(filtroSelecionado);
                    break;
                case MAIOR:
                    filtroQuery = this.montarFiltroMaior(filtroSelecionado);
                    break;
                case MENOR:
                    filtroQuery = this.montarFiltroMenor(filtroSelecionado);
                    break;
                case MAIOR_IGUAL:
                    filtroQuery = this.montarFiltroMaiorIgual(filtroSelecionado);
                    break;
                case MENOR_IGUAL:
                    filtroQuery = this.montarFiltroMenorIgual(filtroSelecionado);
                    break;
                case PREENCHIDO:
                    filtroQuery = this.montarFiltroPreenchido(filtroSelecionado);
                    break;
                case NAO_PREENCHIDO:
                    filtroQuery = this.montarFiltroNaoPreenchido(filtroSelecionado);
                    break;
            }

            if (index == 0) {
                filtroAberturaParenteses = filtroQuery;
            }

            if (index > 0) {
                if (!parentesesAberto && filtroSelecionado.composicao == COMPOSICAO_OR) {
                    query = query.replace(filtroAberturaParenteses, `(${filtroAberturaParenteses}`);
                    parentesesAberto = true;
                    query += ' OR ';
                } else if (parentesesAberto && filtroSelecionado.composicao == COMPOSICAO_OR) {
                    query += ' OR ';
                } else if (!parentesesAberto && filtroSelecionado.composicao == COMPOSICAO_AND) {
                    filtroAberturaParenteses = filtroQuery;
                    query += ' AND ';
                } else if (parentesesAberto && filtroSelecionado.composicao == COMPOSICAO_AND) {
                    parentesesAberto = false;
                    filtroAberturaParenteses = filtroQuery;
                    query += ') AND ';
                }
            }

            query += filtroQuery;

            if (parentesesAberto) {
                query += ')';
            }
        }

        return query;
    }

    private montarTermoContemEmLista(termo: string) {
        return this.formatoSql
            ? ''
            : `.Any(x => x.ToUpper().Contains("${termo.toUpperCase()}"))`
    }

    private montarTermoContemIndividual(termo: string) {
        return this.formatoSql
            ? `like UPPER('%${termo}%')`
            : `.ToUpper().Contains("${termo.toUpperCase()}")`;
    }

    private montarTermoContem(termo: string, pesquisaEmLista: boolean) {
        return pesquisaEmLista
            ? this.montarTermoContemEmLista(termo)
            : this.montarTermoContemIndividual(termo);
    }

    private montarFiltroContem(filtroSelecionado: FiltroSelecionado): string {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;
        let termo = '';
        switch (tipoFiltro) {
            case TEXT:
                termo = this.montarTermoContem(filtroSelecionado.termo, filtro.pesquisaEmLista);

                if (possuiPlaceHolder) {
                    return StringHelper.replaceAll(filtroSelecionado.propriedade, FILTRO_PLACE_HOLDER, termo)
                } else {
                    return this.formatoSql
                        ? `UPPER(${filtroSelecionado.propriedade}) ${termo}`
                        : `${filtroSelecionado.propriedade}${termo}`;
                }
            case ENUM:
                termo = filtroSelecionado.termo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(' ', '');
                termo = this.montarTermoContem(termo, filtro.pesquisaEmLista);

                if (possuiPlaceHolder) {
                    return StringHelper.replaceAll(filtroSelecionado.propriedade, FILTRO_PLACE_HOLDER, termo)
                } else {
                    return this.formatoSql
                        ? `UPPER(${filtroSelecionado.propriedade}) ${termo}`
                        : `${filtroSelecionado.propriedade}${termo}`;
                }
            default:
                return '';
        }
    }

    private montarFiltroNaoContemComPlaceHolder(propriedade: string, termo: string) {
        return this.formatoSql
            ? `(${StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, ' is null')} OR UPPER(${StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, '')}) NOT like '%${termo.toUpperCase()}%')`
            : `(${StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, ' == null')} OR ` +
            `!${StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, `.ToUpper().Contains("${termo.toUpperCase()}")`)})`;
    }

    private montarFiltroNaoContemSemPlaceHolder(propriedade: string, termo: string) {
        return this.formatoSql
            ? `(${propriedade} is null OR UPPER(${propriedade}) NOT like '%${termo.toUpperCase()}%')`
            : `(${propriedade} == null OR !${propriedade}.ToUpper().Contains("${termo.toUpperCase()}"))`;
    }

    private montarFiltroNaoContem(filtroSelecionado: FiltroSelecionado): string {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case TEXT:
                return (possuiPlaceHolder)
                    ? this.montarFiltroNaoContemComPlaceHolder(filtroSelecionado.propriedade, filtroSelecionado.termo)
                    : this.montarFiltroNaoContemSemPlaceHolder(filtroSelecionado.propriedade, filtroSelecionado.termo);
            case ENUM:
                let termo = filtroSelecionado.termo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(' ', '');

                return (possuiPlaceHolder)
                    ? this.montarFiltroNaoContemComPlaceHolder(filtroSelecionado.propriedade, filtroSelecionado.termo)
                    : this.montarFiltroNaoContemSemPlaceHolder(filtroSelecionado.propriedade, termo);
            default:
                return '';
        }
    }

    private montarFiltroString(condicao: string, termo: string, propriedade: string, toUpper: boolean, possuiPlaceHolder: boolean) {
        const termoToUpper = () => this.formatoSql ? `${condicao} '${termo.toUpperCase()}'` : `.ToUpper() ${condicao} "${termo.toUpperCase()}"`;
        const termoDefault = () => this.formatoSql ? ` ${condicao} '${termo}'` : ` ${condicao} "${termo}"`;

        let termoTratado = toUpper ? termoToUpper() : termoDefault();
        let propriedadeTratada = toUpper && this.formatoSql ? `UPPER(${propriedade}) ` : propriedade;

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedadeTratada}${termoTratado}`;
    }

    private montarFiltroStringEmLista(condicao: string, termo: string, propriedade: string, toUpper: boolean, possuiPlaceHolder: boolean) {
        let termoTratado = toUpper
            ? `.Any(x => x.ToUpper() ${condicao} "${termo.toUpperCase()}")`
            : `.Any(x => x ${condicao} "${termo}")`;

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`;
    }

    private montarFiltroIgualDateEmLista(propriedade: string, termoInicio: string, termoFim: string) {
        return `${propriedade}.Any(x => x >= "${termoInicio}" AND x <= "${termoFim}")`;
    }

    private montarFiltroIgualDate(propriedade: string, termoInicio: string, termoFim: string) {
        return this.formatoSql
            ? `(${propriedade} >= '${termoInicio}' AND ${propriedade} <= '${termoFim}')`
            : `(${propriedade} >= "${termoInicio}" AND ${propriedade} <= "${termoFim}")`;
    }

    private montarFiltroPadrao(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        let termoTratado = `${condicao}${termo}`

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`
    }

    private montarFiltroIgual(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;
        const condicao = this.formatoSql ? IGUAL_SQL : IGUAL;

        switch (tipoFiltro) {
            case TEXT:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroStringEmLista(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, true, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, true, possuiPlaceHolder);
            case NUMBER:
                return this.montarFiltroPadrao(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case BOOLEAN:
                return this.montarFiltroPadrao(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case ENUM:
                let termo = filtroSelecionado.termo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(' ', '');

                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroStringEmLista(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case OPTIONS:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroStringEmLista(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case GUID:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroStringEmLista(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case DATE:
                const termoInicio = this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd 00:00:00`);
                const termoFim = this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd 23:59:59`);

                return filtro.pesquisaEmLista
                    ? this.montarFiltroIgualDateEmLista(filtroSelecionado.propriedade, termoInicio, termoFim)
                    : this.montarFiltroIgualDate(filtroSelecionado.propriedade, termoInicio, termoFim);
            default:
                return '';
        }
    }

    montarFiltroDiferenteDate(propriedade: string, termoInicio: string, termoFim: string) {
        return this.formatoSql
            ? `(${propriedade} < '${termoInicio}' OR ${propriedade} > '${termoFim}')`
            : `(${propriedade} < "${termoInicio}" OR ${propriedade} > "${termoFim}")`;
    }

    montarFiltroDiferenteDateEmLista(propriedade: string, termoInicio: string, termoFim: string) {
        return `${propriedade}.Any(x => x < "${termoInicio}" OR x > "${termoFim}")`;
    }

    private montarFiltroDiferente(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;
        const condicao = this.formatoSql ? DIFERENTE_SQL : DIFERENTE;

        switch (tipoFiltro) {
            case TEXT:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroStringEmLista(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, true, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, true, possuiPlaceHolder);
            case NUMBER:
                return this.montarFiltroPadrao(condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case ENUM:
                let termo = filtroSelecionado.termo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(' ', '');

                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroStringEmLista(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case OPTIONS:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroStringEmLista(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case GUID:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroStringEmLista(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder)
                    : this.montarFiltroString(condicao, termo, filtroSelecionado.propriedade, false, possuiPlaceHolder);
            case DATE:
                const termoInicio = this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd 00:00:00`);
                const termoFim = this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd 23:59:59`);

                return filtro.pesquisaEmLista
                    ? this.montarFiltroDiferenteDateEmLista(filtroSelecionado.propriedade, termoInicio, termoFim)
                    : this.montarFiltroDiferenteDate(filtroSelecionado.propriedade, termoInicio, termoFim);
            default:
                return '';
        }
    }

    private montarFiltroMaiorDate(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroString(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMaiorDateEmLista(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroStringEmLista(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMaior(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case NUMBER:
                return this.montarFiltroPadrao(filtroSelecionado.condicao, filtroSelecionado.termo, filtro.propriedade, possuiPlaceHolder);
            case DATE:
                let horaLimite = '23:59:59';
                let termo = `${this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd ${horaLimite}`)}`;

                return filtro.pesquisaEmLista
                    ? this.montarFiltroMaiorDateEmLista(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroMaiorDate(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }

    private montarFiltroMenorDate(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroString(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMenorDateEmLista(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroStringEmLista(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMenor(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case NUMBER:
                return this.montarFiltroPadrao(filtroSelecionado.condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case DATE:
                let horaLimite = '00:00:00'
                let termo = `${this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd ${horaLimite}`)}`;

                return filtro.pesquisaEmLista
                    ? this.montarFiltroMenorDateEmLista(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroMenorDate(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }

    private montarFiltroMaiorIgualDate(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroString(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMaiorIgualDateEmLista(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroStringEmLista(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMaiorIgual(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case NUMBER:
                return this.montarFiltroPadrao(filtroSelecionado.condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case DATE:
                let horaLimite = '00:00:00'
                let termo = `${this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd ${horaLimite}`)}`;

                return filtro.pesquisaEmLista
                    ? this.montarFiltroMaiorIgualDateEmLista(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroMaiorIgualDate(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }

    private montarFiltroMenorIgualDate(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroString(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMenorIgualDateEmLista(condicao: string, termo: string, propriedade: string, possuiPlaceHolder: boolean) {
        return this.montarFiltroStringEmLista(condicao, termo, propriedade, false, possuiPlaceHolder);
    }

    private montarFiltroMenorIgual(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case NUMBER:
                return this.montarFiltroPadrao(filtroSelecionado.condicao, filtroSelecionado.termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            case DATE:
                let horaLimite = '23:59:59';
                let termo = `${this.datePipe.transform(new Date(filtroSelecionado.termo), `yyyy-MM-dd ${horaLimite}`)}`;

                return filtro.pesquisaEmLista
                    ? this.montarFiltroMenorIgualDateEmLista(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroMenorIgualDate(filtroSelecionado.condicao, termo, filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }

    private montarFiltroPreenchidoEmLista(propriedade: string, possuiPlaceHolder: boolean) {
        let termoTratado = `.Any(x => x != null)`;

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`;
    }

    private montarFiltroPreenchidoIndividual(propriedade: string, possuiPlaceHolder: boolean) {
        let termoTratado = this.formatoSql ? ' is not null' : ' != null';

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`;
    }

    private montarFiltroPreenchido(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case TEXT:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case NUMBER:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case BOOLEAN:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case ENUM:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case OPTIONS:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case GUID:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case DATE:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }

    private montarFiltroNaoPreenchidoEmLista(propriedade: string, possuiPlaceHolder: boolean) {
        let termoTratado = `.Any(x => x == null)`;

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`;
    }

    private montarFiltroNaoPreenchidoIndividual(propriedade: string, possuiPlaceHolder: boolean) {
        let termoTratado = this.formatoSql ? ' is null' : ' == null';

        return possuiPlaceHolder
            ? StringHelper.replaceAll(propriedade, FILTRO_PLACE_HOLDER, termoTratado)
            : `${propriedade}${termoTratado}`;
    }

    private montarFiltroNaoPreenchido(filtroSelecionado: FiltroSelecionado) {
        const filtro = this.filtros.find(x => x.propriedade == filtroSelecionado.propriedade);
        const tipoFiltro = filtro?.tipo;
        const possuiPlaceHolder = filtroSelecionado.propriedade.indexOf(FILTRO_PLACE_HOLDER) >= 0;

        switch (tipoFiltro) {
            case TEXT:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case NUMBER:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case BOOLEAN:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case ENUM:
                return (filtro.pesquisaEmLista)
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case OPTIONS:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case GUID:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            case DATE:
                return filtro.pesquisaEmLista
                    ? this.montarFiltroNaoPreenchidoEmLista(filtroSelecionado.propriedade, possuiPlaceHolder)
                    : this.montarFiltroNaoPreenchidoIndividual(filtroSelecionado.propriedade, possuiPlaceHolder);
            default:
                return '';
        }
    }
}
